<template>
  <div>
    <Breadcrumbs
      main=""
      title="Users"
      class="showBreadcrumbs"
      :display="isBreadView"
    />
    <!-- <Breadcrumbs main="" title="Users" :display="isBreadView" /> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-row class="align-items-start">
            <b-col class="col-md-6">
              <span class="custome-title">Users</span>
            </b-col>
            <b-col class="text-right col-md-3 mt-1 col-12">
              <b-form-group
                label-cols="7"
                label="Per page"
                class="md-0 custom-font-18"
              >

                <b-form-select
                  class="border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="col-md-3 col-12 mt-1 date-range-custom">
              <b-input-group class="">
                <b-input-group-prepend>
                  <span class="input-group-text search-bg"
                    ><i class="icofont icofont-search-alt-2"></i
                  ></span>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  class="border-left-0"
                  v-model="filter"
                  placeholder="Type to Search"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <div class="col-md-12" v-if="loading">
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </div>
          <div
            v-else
            class="table-responsive datatable-vue custom-font-16 user-table"
          >

            
            <!-- New changes starts -->

            <b-table
              show-empty
              stacked="md"
              :items="items"
              :filter="filter"
              
              :per-page="perPage"
              @filtered="onFiltered"
              :fields="tablefields"
            >
              <template #table-caption>User List.</template>

              <template #cell(index)="data">
                {{ (currentPage - 1) * perPage + data.index + 1 }}
              </template>

              <template #cell(email)="data">
                <span class="">{{ data.item.email }}</span>
              </template>

              <template #cell(country)="data">
                <multiselect
                  class="custom-multiselect-badge"
                  :options="countryOpt"
                  label="name"
                  track-by="id"
                  v-model="data.item.countryArray"
                  placeholder="Select Country"
                  :multiple="true"
                  :max="5"
                  @input="assignCountry(data.item)"
                >
                </multiselect>
              </template>

              <template #cell(role)="data">
                <span class="custom-font-18">
                  <b-form-select
                    class="col-md-10"
                    v-model="data.item.role"
                    :options="role_opt"
                    @change="roleUpdate(data.item)"
                  ></b-form-select>
                </span>
              </template>
              <template #cell(isactive)="data">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="data.item.isactive"
                      @change="statusUpdate(data.item)"
                    />
                    <span
                      class="switch-state"
                      :class="data.item.isactive ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
            </b-table>

            <!-- New changes ends -->



          </div>

  
          <b-col class="mt-3 p-0">
            <div class="pagination">
              <a v-show="currentPage > 1" @click="changePage(currentPage - 1)" :disabled="loading">« Back</a>
              <span v-for="page in totalPages" :key="page">
                <a :class="{ active: currentPage === page }" @click="changePage(page)" :disabled="loading">{{ page }}</a>
              </span>
              <a v-show="currentPage < totalPages" @click="changePage(currentPage + 1)" :disabled="loading">Next »</a>
            </div>
          </b-col>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserController from "../../../services/UserService";
import NetworkTypeService from "../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  name: "Users",
  components: {
    Multiselect,
  },
  data() {
    return {
      allocatePlan: false,
      input: { plan_id: null },
      plan_opt: [{ value: null, text: "select plan to allocate" }],
      plans: [],
      items: [],
      totalRows: 0,
      filter: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      loading: false,
      tablefields: [
        "index",
        { key: "username", label: "User Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "role", label: "Role", sortable: true },
        { key: "country", label: "Country", sortable: true },
        { key: "isactive", label: "Is Active", sortable: false },
      ],
      role_opt: { user: "User", moderator: "Moderator", tester: "Tester" },
      userinfo: [],
      user_data: [],
      countryOpt: [],
      totalPages: 0,
    };
  },
  mounted() {
    this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
    this.getUsers();
    this.getCountry();
  },
  methods: {
    


    async getUsers(page = 1) {
  this.loading = true;
  this.items = [];
  this.currentPage = page;
  let options = {
    page: this.currentPage,
    limit: this.perPage,
  };
  try {
    let response = await UserController.allUsersList(options);
    if (response.result) {
      this.items = response.message.filter((t) => t.role !== "manager");
      this.totalRows = response.pagination.totalItems;
      this.currentPage = response.pagination.currentPage;
      this.perPage = response.pagination.pageSize;
      this.totalPages = response.pagination.totalPages;
    } else {
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000,
      });
    }
  } catch (error) {
    this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000,
      });
  } finally {
    this.loading = false;
  }
},


    async changePage(newPage) {
      if (newPage >= 1 && newPage ) {
        await this.getUsers(newPage);
      }
    },

    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.getUsers(this.currentPage);
    },

    onFiltered(filteredItems) {      
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async statusUpdate(data) {
      if (data.countryArray.length > 0) {
        let status = true;
        if (data.isactive == true) {
          status = false;
        }
        if (data.isactive == false) {
          data.isactive = true;
        } else {
          data.isactive = false;
        }
        var payload = {
          useridTobeChange: data._id,
          tobeModify: { isactive: status },
        };
        let response = await UserController.updateUser(payload);
        if (response.result) {
          const toastMessage = data.isactive ? "User Activated" : "User Deactivated";
          this.$toasted.show(toastMessage || response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          // this.getUsers()
        }
      } else {
        this.$toasted.show("Please assign at least one country ", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
    },
    async roleUpdate(data) {
      if (confirm("Are you sure to change role?")) {
        var payload = {
          useridTobeChange: data._id,
          tobeModify: { role: data.role },
        };
        let response = await UserController.updateUser(payload);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
        }
      } else {
        data.role = data.role == "user" ? "moderator" : "user";
        // console.log(data.role)
      }
    },
    async assignCountry(data) {
      var payload = {
        useridTobeChange: data._id,
        tobeModify: { countryArray: data.countryArray },
      };
      // if(data.countryArray.length < 1){
      //   var payload = {"useridTobeChange": data._id, "tobeModify": {isactive: false,countryArray: data.countryArray}}
      // }
      let response = await UserController.updateUser(payload);
      if (response.result) {
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "success",
          duration: 2000,
        });
      }
    },
    changeDateFormate(d) {
      return moment.utc(d).format("D/M/YYYY, HH A");
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
  },
};
</script>

<style>
.multiselect__tag {
  background-color: #274aa6 !important;
}
</style>

<style scoped>
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
